// src/utils/StatsManager_Olympics.js
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

// Function to get the medal count for a specific user ID, program, and medal type
const getMedalCount = async (program, userId, medalType) => {
  console.log(`Getting ${medalType} medal count for userId: ${userId} in ${program}`);
  try {
    const userStatsRef = doc(db, 'users', userId, 'stats', program);
    const userStatsDoc = await getDoc(userStatsRef);
    if (userStatsDoc.exists()) {
      const stats = userStatsDoc.data();
      return stats[medalType] ?? 0; // Use nullish coalescing to return 0 if stats[medalType] is undefined or null
    } else {
      return 0;
    }
  } catch (error) {
    console.error(`Error getting ${medalType} medal count for userId: ${userId} in ${program}`, error);
    return 0;
  }
};

export const getGoldMedalCount = (program, userId) => getMedalCount(program, userId, 'gold');
export const getSilverMedalCount = (program, userId) => getMedalCount(program, userId, 'silver');
export const getBronzeMedalCount = (program, userId) => getMedalCount(program, userId, 'bronze');

// Function to get the team for a specific user ID and year
export const getTeamForUserId = async (userId, year) => {
  console.log(`Getting team for userId: ${userId} in ${year}`);
  try {
    const userStatsRef = doc(db, 'users', userId, 'stats', `BO${year}`);
    const userStatsDoc = await getDoc(userStatsRef);
    if (userStatsDoc.exists()) {
      const stats = userStatsDoc.data();
      return stats.team || 'N/A';
    } else {
      return 'N/A';
    }
  } catch (error) {
    console.error(`Error getting team for userId: ${userId} in ${year}`, error);
    return 'N/A';
  }
};

// Function to fetch all team results
export const fetchTeamResults = async (year) => {
  console.log(`Fetching team results for year: ${year}`);
  try {
    const resultsDocRef = doc(db, 'results', `BO${year}`);
    const resultsDocSnap = await getDoc(resultsDocRef);
    if (resultsDocSnap.exists()) {
      const results = resultsDocSnap.data();
      return results.teams || {};
    } else {
      console.warn(`No team results found for BO${year}`);
      return {};
    }
  } catch (error) {
    console.error(`Error fetching team results for year: ${year}`, error);
    return {};
  }
};

// Function to fetch all personal results
export const fetchPersonalResults = async (year) => {
  console.log(`Fetching personal results for year: ${year}`);
  try {
    const resultsDocRef = doc(db, 'results', `BO${year}`);
    const resultsDocSnap = await getDoc(resultsDocRef);
    if (resultsDocSnap.exists()) {
      const results = resultsDocSnap.data();
      return results.stats || {};
    } else {
      console.warn(`No personal results found for BO${year}`);
      return {};
    }
  } catch (error) {
    console.error(`Error fetching personal results for year: ${year}`, error);
    return {};
  }
};

// Function to fetch all event results
export const fetchEventResults = async (year) => {
  console.log(`Fetching event results for year: ${year}`);
  try {
    const resultsDocRef = doc(db, 'results', `BO${year}`);
    const resultsDocSnap = await getDoc(resultsDocRef);
    if (resultsDocSnap.exists()) {
      const results = resultsDocSnap.data();
      return results.events || [];
    } else {
      console.warn(`No event results found for BO${year}`);
      return [];
    }
  } catch (error) {
    console.error(`Error fetching event results for year: ${year}`, error);
    return [];
  }
};