import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import PersistentLayout from './components/GUI/Page/PersistentLayout';
import './styles/global.css';

const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));
const Home = lazy(() => import('./pages/Home/Home'));
const Login = lazy(() => import('./pages/Login/Login'));
const Register = lazy(() => import('./pages/Register/Register'));
const Stats = lazy(() => import('./pages/Stats/Stats'));
const Calendar = lazy(() => import('./pages/Calendar/Calendar'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const AnnualAwards = lazy(() => import('./pages/Stats/AnnualAwards/AnnualAwards'));
const BapeOlympics = lazy(() => import('./pages/Stats/BapeOlympics/BapeOlympics'));
const Pistolshrimps = lazy(() => import('./pages/Stats/Pistolshrimps/Pistolshrimps'));
const FifaRanking = lazy(() => import('./pages/Stats/FifaRanking/FifaRanking'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<PersistentLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/bape-olympics" element={<BapeOlympics />} />
          <Route path="/annual-awards" element={<AnnualAwards />} />
          <Route path="/pistolshrimps" element={<Pistolshrimps />} />
          <Route path="/fifa-ranking" element={<FifaRanking />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;