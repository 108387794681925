// src/utils/StatsManager_wandl.js
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

// Function to get the recipients for a specific year or all years
const getRecipients = async (type, year) => {
  console.log(`Getting ${type} of the Year recipients for ${year}`);
  try {
    if (year === 'all') {
      const wandlCollectionRef = collection(db, 'wandl');
      const wandlSnapshot = await getDocs(wandlCollectionRef);
      const recipients = wandlSnapshot.docs
        .filter(doc => doc.id.startsWith(`${type}OfTheYear`))
        .map(doc => ({
          year: doc.id.split('_')[1],
          recipients: doc.data().recipients.map(recipient => ({
            name: recipient.name,
            reason: recipient.reason || 'Reason not provided',
          })) || 'Award not given out this year',
        }));
      return recipients;
    } else {
      const wandlRef = doc(db, 'wandl', `${type}OfTheYear_${year}`);
      const wandlDoc = await getDoc(wandlRef);
      if (wandlDoc.exists()) {
        return wandlDoc.data().recipients.map(recipient => ({
          name: recipient.name,
          reason: recipient.reason || 'Reason not provided',
        })) || 'Award not given out this year';
      } else {
        return 'Award not given out this year';
      }
    }
  } catch (error) {
    console.error(`Error getting ${type} of the Year recipients for ${year}`, error);
    return 'Error fetching data';
  }
};

export const getWOfTheYearRecipients = (year) => getRecipients('W', year);
export const getLOfTheYearRecipients = (year) => getRecipients('L', year);