import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAllUserData } from '../utils/userGetters';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [profilePicUrl, setProfilePicUrl] = useState(localStorage.getItem('profilePicUrl') || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [attributes, setAttributes] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const saveUserId = (id) => {
    setUserId(id);
    localStorage.setItem('userId', id);
  };

  const saveProfilePicUrl = (url) => {
    setProfilePicUrl(url);
    localStorage.setItem('profilePicUrl', url);
  };

  useEffect(() => {
    if (userId && !profilePicUrl) {
      const url = `${process.env.PUBLIC_URL}/profilepics/${userId}.png`;
      saveProfilePicUrl(url);
    }
  }, [userId, profilePicUrl]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getAllUserData(userId);
        if (data) {
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setAttributes(data.attributes || {});
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  useEffect(() => {
    const adminUserIds = ['3cVkOeayXgS4GxE0j4zekGJVdHu1'];
    setIsAdmin(adminUserIds.includes(userId));
  }, [userId]);

  return (
    <UserContext.Provider value={{ userId, saveUserId, profilePicUrl, saveProfilePicUrl, firstName, lastName, attributes, isAdmin }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);