// src/utils/StatsManager.js
import {
  getGoldMedalCount as getOlympicGoldMedalCount,
  getSilverMedalCount as getOlympicSilverMedalCount,
  getBronzeMedalCount as getOlympicBronzeMedalCount,
  getTeamForUserId as getOlympicTeamForUserId,
  fetchTeamResults,
  fetchPersonalResults,
  fetchEventResults,
} from './StatsManager_Olympics';

// Export Olympic-related functions with more readable names
export const getGoldMedalCount = getOlympicGoldMedalCount;
export const getSilverMedalCount = getOlympicSilverMedalCount;
export const getBronzeMedalCount = getOlympicBronzeMedalCount;
export const getTeamForUserId = getOlympicTeamForUserId;

// Function to fetch all results for a specific year
export const fetchAllResults = async (year) => {
  console.log(`Fetching all results for year: ${year}`);
  try {
    const [teamResults, personalResults, eventResults] = await Promise.all([
      fetchTeamResults(year),
      fetchPersonalResults(year),
      fetchEventResults(year),
    ]);

    return {
      teams: teamResults,
      personal: personalResults,
      events: eventResults,
    };
  } catch (error) {
    console.error(`Error fetching all results for year: ${year}`, error);
    return {
      teams: {},
      personal: {},
      events: [],
    };
  }
};